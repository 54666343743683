<template>
  <v-container>
    <v-row class="justify-center ">
      <v-col
        cols="12"
        md="5"
      >
        <v-snackbar
          v-model="snackbar"
          :timeout="3000"
          top
          :color="color"
          dark
        >
          {{ text }}
          <v-btn
            color="white"
            text
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </v-snackbar>

        <v-card>
          <v-flex xs12>
            <v-toolbar
              dense
              color="primary"
              dark
            >
              <v-toolbar-title> Cambiar Contraseña </v-toolbar-title>
            </v-toolbar>
          </v-flex>
        </v-card>

        <v-card
          :loading="loading"
          class="mt-1 pa-3"
          align="center"
        >
          <v-img
            contain
            :src="logo"
            width="250"
            max-height="200"
            class="mb-2"
          />
        
          <!-- EMAIL -->
          <v-flex
            xs12
            class="pa-1"
          >
            <v-text-field
              v-model="email"
              prepend-icon="email"
              label="Correo"
              required
              type="text"
            />
          </v-flex>

          <v-flex
            xs12
            class="pa-1"
          >
            <v-text-field
              v-model="password"
              prepend-icon="lock"
              :append-icon="show1 ? 'visibility_off' : 'visibility'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Nueva contraseña"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            />
          </v-flex>

          <v-flex
            xs12
            class="pa-1"
          >
            <v-text-field
              v-model="password2"
              prepend-icon="lock"
              :append-icon="show2 ? 'visibility_off' : 'visibility'"
              :rules="[rules.required, rules.min]"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              label="Confirma contraseña"
              hint="At least 8 characters"
              counter
              @click:append="show2 = !show2"
            />
          </v-flex>
        
          <v-flex xs12>
            <v-card
              v-if="error"
              color="red lighten-1"
              class=" px-2 py-2"
            >
              <div class="white--text">
                {{ error }}
              </div>
            </v-card>
          </v-flex>

          <v-divider />
        
          <v-card-actions class="mt-1 mx-3">
            <v-btn
              dark
              small
              color="grey lighten-1"
              :to="{name:'login'}"
            >
              Cancelar
            </v-btn>
            <v-spacer />
            <v-btn
              small
              color="primary"
              dark
              class="elevation-5"
              @click="cambiarContra"
            >
              Procesar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      logo:'https://soportesait.com/soporte1.png',
      id: '',
      EmailOrg: '',
      loading: true,
        
      password:'',
      password2:'',

      email:'',

      snackbar: false,
      text: '',
      color: '',

      show1: true,
      show2: true,
      isPaste: false,
      drawer: null,
      error: '',


      rules: {
        required: value => !!value || 'Requerido.',
        min: v => v.length >= 8 || 'Minimo 8 caracteres',
        emailMatch: () => ('El correo y/o la contraseña no son correctos')
      },

      NombreAsig: '',
      Nomuser: '',
    }

  },

  computed:{
    // ...mapGetters('', ['']),
    validaInfo(){
      return  this.email        !==  '' &&
              this.password     !==  '' &&
              this.password2    !==  ''
    },

    validaPassword(){
      return this.password.localeCompare(this.password2)
    }
  },


  created () {
    this.id = this.$route.params.id
    // MANDO A TRAER LOS DATOS DEL USUARIO "ID"
    this.DatosUser()
  },


  methods:{

    DatosUser(){
      this.loading = true

      const payload = { Idusuariosweb : this.id }
      console.log("pay", payload)

      this.$http.post('getuserxid', payload).then(response=>{
        console.log(response)
        this.EmailOrg = response.data.email
        this.email = response.data.email
        this.loading= false
      }).catch(error=>{
        console.log(error)
      })

    },

    cambiarContra(){
      this.snackbar = false
      var me = this

      if(!this.validaInfo){
        this.text     = "Faltan campos por agregar"; this.snackbar = true
        this.color    = "orange"; this.timeout = 3000;
        return
      }

      if(this.validaPassword != 0){
        this.text     = "Las contraseñas no coinciden" ; this.snackbar = true
        this.color    = "orange" ; this.timeout = 3000;
        return
      }

      if(this.EmailOrg != this.email){
        this.text     = "El email no coincide con el número de usuario" ; this.snackbar = true
        this.color    = "red" ; this.timeout = 3000;
        return
      }

      this.error = null;
      var md5 = require('md5');

      var payload = { password : md5(this.password)}

      console.log(payload)

      return new Promise(() => {
        
        this.$http.put('cambiarcontra/'+ this.id, payload).then(respuesta=>{
          return respuesta.json()
        }).then(respuestaJson=>{
          if(respuestaJson === null){
            this.snackbar= true ; this.text="Lo sentimos, no se logro cambiar la contraseña."
            this.color    = "orange";
          }else{
            this.snackbar= true ; this.text="La contraseña se ha actualizado correctamente."
            this.color    = "green";

            setTimeout(function(){ me.$router.push({name: 'login'}) }, 3000);
          }
        }).catch(error=>{console.log('error',error)})
      })
    }
  }
};
</script>

<style scoped>
  .logo{
    align-content: "center";
    text-align: center;

  }

</style>